import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import cookie from 'react-cookie'

import { Overlay } from '../'
import * as flags from 'svgs/flags/Flags'
import { countrySelector } from 'utils/i18n'

import styles from './countryselector.css'

const CountrySelector = ({
  currentLocale,
  countries,
  locales,
  close,
  isOpen
}) => {
  useEffect(() => {
    //on mount - if overlay is open set focus on first available locale
    if (isOpen) localeRefs[0].current?.focus()
  })

  //init array to store all locale refs
  let localeRefs = []

  const siteText = {
    countrySelector: countrySelector()
  }
  const getUrl = (locale) => {
    if (!locale) return ''
    if (locale.redirectUrl) return locale.redirectUrl
    const urlLocale = locale.friendlyLocaleCode || locale.contentfulLocaleCode
    return `/${urlLocale}`
  }

  return (
    <Overlay
      isOpen={isOpen}
      close={close}
      overlayClass={styles.overlay}
      overlayId="countrySelectorOverlay"
    >
      <div className={styles.headline}>
        <h2>{siteText.countrySelector}</h2>
      </div>

      <div className={styles.countries}>
        {countries.map((country) => {
          if (country.locales.length === 0) return null
          return (
            <div key={country.id} className={styles.country}>
              <span className={styles.flag}>
                {typeof flags[country.flag] === 'function' &&
                  flags[country.flag]()}
              </span>
              {country.locales.map((code) => {
                //create new ref, push it to main refs array and assign to DOM element
                const ref = useRef()
                localeRefs.push(ref)

                return (
                  <button
                    ref={ref}
                    type="button"
                    key={code}
                    className={classNames({
                      [styles.locale]: true,
                      [styles.localeActive]: currentLocale === code
                    })}
                    onClick={() => {
                      cookie.save("ndh.locale", locales[code], {
                        path: "/",
                        domain: ".jagermeister.com",
                        maxAge: 60 * 60 * 24 * 30 * 6, // 6 months
                      })
                      window.location.href = getUrl(locales[code])
                    }}
                  >
                    {locales[code] && locales[code].title}
                  </button>
                )
              })}
              <span className={styles.name}>{country.title}</span>
            </div>
          )
        })}
      </div>
    </Overlay>
  )
}

CountrySelector.propTypes = {
  isOpen: PropTypes.bool,
  countries: PropTypes.array.isRequired,
  locales: PropTypes.object.isRequired,
  currentLocale: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
}

CountrySelector.defaultProps = {
  isOpen: false
}

export default CountrySelector
